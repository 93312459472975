<template>
  <section class="m_section">
    <!--	s: 	contents-->
    <div class="contents">
      <MobileGnb />
      <div class="tabmenu_wrap">
        <ul class="tabmenu">
          <li class="tabl on"><span>이용약관</span></li>
          <li class="tabl" @click="$router.push({ path: '/policy/chargeNotice' })">
            <span>요금신고<br />내역</span>
          </li>
          <li class="tabl" @click="$router.push({ path: '/policy/privacyPolicy' })">
            <span>개인정보<br />처리방침</span>
          </li>
          <li class="tabl" @click="$router.push({ path: '/policy/spamPolicy' })"><span>스팸정책</span></li>
        </ul>
      </div>
      <div class="tabcont_wrap policy_wrap">
        <div class="policy useTerms">
          <div class="policy_top">
            <h3 class="policy_title">이용약관</h3>
            <p class="location">HOME - 이용약관</p>
          </div>
          <div class="requestWrap">
            <div class="requestTextArea" style="max-height: 395px">
              <!-- 이용약관 동의 박스 시작. -->
              <h5>U+ 메시지허브이지 서비스 이용약관</h5>
              <h6>제 1 장 총 칙</h6>
              <div>
                제1조 (목적) <br />
                본 이용약관(이하 ‘약관”)은 주식회사 엘지유플러스(이하 “회사”)가 제공하는 U+메시지허브이지 서비스(이하 “서비스”) 관련한 이용조건 및 절차와 기타 필요한 제반 사항을 규정함을 목적으로
                합니다.
              </div>
              <div>
                제2조 (약관의 인가, 효력 및 변경)
                <ol>
                  <li>회사는 본 약관을 서비스 홈페이지에 게시하는 방법으로 공지하며, 서비스 가입 신청 시 이용고객으로부터 약관에 대한 동의를 받습니다.</li>
                  <li>회사는 "약관의규제에관한법률", "정보통신망이용촉진및정보보호등에관한법률(이하 "정보통신망법")" 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.</li>
                  <li>
                    회사가 전항에 따라 개정약관을 공지 또는 통지하면서 이용고객에게 30일 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 이용고객이 명시적으로 거부의 의사표시를 하지 아니한 경우 이용고객이 개정약관에 동의한 것으로 봅니다. 단, 회사가 제공하는 서비스와 관련한 중요한 사항(담보기준, 서비스 정지 기준, 이용계약의 해제 및 해지)이 변경되는 경우 2개월 전에 변경 내용을 해당 고객에게 전화, 문자메시지, 이메일, 청구서 등을 통해 고지하고 홈페이지에 변경 내용을 게시합니다.  <br />
                    그리고 위 중요한 사항이 고객에게 실질적으로 불리하게 변경되는 경우에는 고객이 위 고지 이후 2개월 이내에 이용계약을 해지할 수 있음을 함께 고지합니다. 위 기간 내에 이용계약을 해지하지 않은 경우 변경된 약관에 승인하는 것으로 본다는 뜻을 고지하였음에도 이용계약을 해지하지 않는 경우 변경된 약관을 승인하는 것으로 봅니다.
                  </li>
                  <li>
                    이용고객이 개정약관의 적용에 동의하지 않는 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 이용고객은 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.
                  </li>
                </ol>
              </div>
              <div>
                제3조 (약관의 적용)
                <ol>
                  <li>이 약관에 명시되지 아니한 사항에 대해서는 관계법령 및 이 약관의 취지에 따라 적용합니다.</li>
                </ol>
              </div>
              <div>
                제4조 (용어의 정의) <br />
                이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                <ol>
                  <li>이용고객(신청자): 서비스를 제공받기 위하여 회사와 이용계약을 체결하고 서비스 ID를 부여 받은 자</li>
                  <li>청구계정번호: 요금청구서(세금계산서)가 발생하는 단위의 식별번호로 1개의 청구계정번호에는 다수의 서비스 ID를 포함할 수 있습니다</li>
                  <li>서비스 ID: 이용고객의 식별과 이용고객의 서비스 이용을 위하여 회사가 부여하는 고유한 문자와 숫자의 조합</li>
                  <li>비밀번호(PASSWORD): 이용고객이 부여 받은 서비스 ID와 일치된 이용고객임을 확인 하고, 이용고객의 이용권한 보호를 위하여 회사가 부여하거나 고객이 직접 설정한 문자와 숫자의 조합</li>
                  <li>해제: 회사 또는 이용고객이 서비스 개통 전에 그 이용계약을 해약하는 것을 말함</li>
                  <li>해지: 회사 또는 이용고객이 서비스 개통 후에 그 이용계약을 해약하는 것을 말함</li>
                  <li>정지: 회사나 이용고객이 일정기간 서비스 이용을 제한 또는 유보하는 것을 말함</li>
                  <li>개통일: 이용고객이 서비스를 신청한 후 회사가 이용고객에게 서비스를 이용할 수 있도록 필요한 조치를 완료한 일자</li>
                  <li>변경일: 이용고객이 서비스 내용에 대한 변경을 요청한 후 회사가 이용고객의 요청사항을 조치하여 완료한 일자</li>
                  <li>기본료: 회사가 제공하는 서비스를 받기 위해 이용고객이 매월 기본적으로 지불해야 하는 비용</li>
                  <li>추가이용료: 회사가 제공하는 서비스에 의해 이용고객이 기본료에 정해진 발송건수를 초과하여 사용하는데 부과되는 비용</li>
                  <li>선불제: 이용고객이 서비스를 이용하기 위해 이용할 서비스 금액을 미리 결제해야 하는 요금제</li>
                  <li>후불제: 이용고객이 서비스를 이용하고 난 후 월 단위로 사용량을 정산하여 그 금액만큼 후불로 결제를 하는 요금제</li>
                  <li>유료충전 (구매/결제): 이용고객이 선불제를 이용하기 위해 회사가 제공하는 특정 선불 결제수단을 선택하여 결제할 때 회사가 이용고객의 결제금액에 해당하는 발송건수를 제공하는 방식</li>
                  <li>월 최저이용료: 이용고객이 서비스를 기본료 없이 사용하고자 할 때 최소 사용량을 보존하기 위한 비용</li>
                  <li>스팸 : 정보통신망을 통해 이용자가 원하지 않는데도 불구하고 일방적으로 전송 또는 게시되는 영리목적의 광고성 정보</li>
                  <li>불법스팸 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 '정보통신망법'이라 합니다) 을 위반하여 전송 또는 게시되는 영리목적의 광고성 정보</li>
                  <li>이용계약: 서비스 이용과 관련하여 회사와 이용고객간에 체결하는 계약</li>
                  <li>재충전: 유료충전 후 서비스를 이용하시는 중에 장애나 기타 사유로 인해 정상적인 서비스의 이용이 불가능했을 경우 해당되는 서비스의 금액만큼 무료충전을 통한 복원</li>
                  <li>휴면ID: 최근 연속하여 5년 이상 회사가 제공하는 서비스 전송이력이 없는 회원</li>
                  <li>M-Gov서비스 : 정부통합전산센터에서 제공하는 모바일 정부 관련하여 정부기관 대상으로 제공하는 기업메시징 중계 서비스</li>
                  <li>발신번호: 문자메시지를 발송하는 이용고객의 전화번호</li>
                  <li>발신번호 사전등록 : 이용고객이 발신번호를 서비스에 사전에 등록하여 서비스를 이용하는 행위</li>
                  <li>발신번호 거짓표시(변작) : 이용고객이 사전등록한 발신번호가 아닌 다른 전화번호를 발신번호로 이용하는 행위</li>
                  <li>번호도용 문자차단 서비스 : 이용고객의 번호를 제3자가 도용하여 스팸문자를 발송하는 것을 차단하기 위해 과학
                      기술정보통신부, 방송통신위원회, 한국인터넷진흥원(KISA)이 공동으로 운영하는 서비스</li>
                  <li>발송한도: 서비스 ID 당 최대 발송할 수 있는 건수(월 기준)</li>
                  <li>SMS (Short Message Service): 이동전화 단문 메시지서비스</li>
                  <li>MMS(Multimedia Message Service): 이동전화 멀티미디어 메시지서비스</li>
                  <li>“RCS 템플릿”: “RCS 서비스”에서 제공되는 정보성 메시지 전송 상품으로 사전에 이통사에서 검수한 양식에 따른 정보성 메시지를 전송하는 상품</li>
                  <li>“RCS 이미지템플릿”: 다양한 스타일의 이미지와 텍스트를 사전에 등록하여 마케팅 메시지를 전송하는 상품</li>
                  <li>“RCS양방향”이란 “RCS서비스”에서 제공되는 정보성 메시지 전송 상품으로, 단말기 문자함에서 이용고객이 수신 고객의 문의/요청 등에 대하여 응답 메시지를 전송하는 상품</li>
                  <li>“대화방메뉴 자동응답 메시지” : 메시지앱의 대화방 하단에 고정적으로 표시되는 대화방 메뉴 선택 시 동작하는 응답 메시지로, 이용고객이 RCS Biz Center에 자동응답 메시지로 등록한 메시지를 이용고객의 수신고객에게 전달하는 기능을 의미하며, “RSC양방향”요금이 부과됨</li>
                  <li>식별코드 : 문자중계사업자 또는 문자 재판매사업자가 자사를 통해 발송된 문자임을 확인할 수 있도록 인터넷 문자메시지를 발송할 때 삽입하는 번호</li>
                </ol>
              </div>
              <div>
                제5조 (서비스영역 등)
                <ol>
                  <li>이 서비스는 회사가 구성한 설비 및 응용기능을 서비스영역으로 합니다.</li>
                  <li>이 서비스는 회사가 구성한 설비 및 응용기능을 품질보증영역으로 합니다.</li>
                </ol>
              </div>
              <h6>제 2 장 계 약 체 결</h6>
              <p>제 1 절 통 칙</p>
              <div>
                제6조 (서비스 종류)
                <ol>
                  <li>
                    서비스 정의
                    <ul>
                      <li>가. SMS(Short Message Service) 서비스: 이동전화 단문서비스의 데이터 통신기능을 활용하여 컴퓨터 등 정보처리 능력을 가지고 있는 장치와 무선단말기 사이에 90Byte 이하의 단문메시지(이하 'SMS')를 전송해 주는 서비스 및 기타 부가하여 제공하는 서비스</li>
                      <li>나. MMS(Multimedia Message Service) 서비스: 이동전화 멀티미디어 메시지서비스의 데이터 통신기능을 활용하여 컴퓨터 등 정보처리 능력을 가지고 있는 장치와 무선단말기 사이에 2,000Byte 이하의 장문메시지, 이미지, 오디오, 동영상의 멀티미디어메시지(이하 'MMS')를 전송해 주는 서비스 및 기타 부가하여 제공하는 서비스</li>
                      <li>다. 메시징 수신서비스 : 수신고객이 전화번호로 전송한 문자메시지를 회사의 서비스 홈페이지 또는 서버를 통해 관리할 수 있는 서비스
                      </li>
                      <li>라. PUSH메시징 서비스: 이용고객의 서버를 통해 수신고객의 스마트폰의 앱(app.)으로 알림 메시지를 전송하는 서비스</li>
                      <li>마. RCS(Rich Communication Suite)서비스 : GSMA(Global System for Mobile Association)가 배포한 RCS(Rich Communication Suite) 규격을 기반으로 글로벌 표준 규격이 적용된 메시지 서비스이며, “RCS 서비스”가 탑재된 단말기에서만 이용가능함. “RCS SMS”(한영 혼용 100자), “RCS MMS”(Text, 한영 혼용 1,300자), “RCS MMS”(Multimedia, 한영 혼용 1,300자와 1MB 이내의 멀티미디어), “RCS 템플릿”(한영 혼용 90자), “RCS이미지 템플릿 서비스, “RCS양방향”으로 구성됨</li>
                      <li>바. 국제 메시징: 해외 이동통신사를 이용하는 수신고객에게 문자메시지를 전송하는 서비스
                      </li>
                      <li>사. 알림톡: 이용고객이 카카오톡에서 수신고객에게 정보성 메시지를 발송하는 서비스</li>
                    </ul>
                  </li>

                  <li>
                    서비스 방식
                    <ul>
                      <li>가. 엘지유플러스 Web Service: 회사가 제공하는 서비스 홈페이지에 접속하여 회사의 시스템을 통해 해당 이동통신사에 SMS 또는 MMS를 전송하는 서비스</li>
                      <li>나. 엘지유플러스 Client Service: 이용고객이 자신의 서버에 회사가 제공하는 특정 모듈과 DB스키마를 세팅하여 회사의 시스템을 통해 해당 이동통신사에 SMS, MMS 또는 RCS 서비스를 전송하는 서비스</li>
                      <li>다. Open API : 이용고객이 자신의 서버에 전송서버 구축 및 DB연동 없이 직접 개발하여 회사의 시스템을 통해 해당 이동통신사에 SMS 또는 MMS를 전송하는 서비스</li>
                    </ul>
                  </li>
                  <li>
                    회사는 제6조(서비스 종류) 제1항(서비스 정의)에 정의된 서비스 외에도 전화, 어플리케이션서비스 등 타 서비스와 연계한 통합서비스 또는 별도의 서비스 등을 제공할 수 있으며 이용조건 등은 별도로 공지하는 바에 따릅니다.
                  </li>
                </ol>
              </div>
              <div>
                제7조 (이용계약의 단위)
                <ol>
                  <li>서비스 이용계약의 단위는 이용고객당 1개의 서비스 ID 단위로 체결하는 것을 원칙으로 합니다. 다만, 회사가 인정하는 경우에 2개 이상의 서비스 ID도 가능합니다.</li>
                </ol>
              </div>
              <p>제 2 절 이 용 계 약</p>
              <div>
                제8조 (이용계약 성립)
                <ol>
                  <li>이용계약은 이용고객의 이용신청에 대한 회사의 이용승낙에 의하여 성립됩니다.</li>
                  <li>선불제일 경우에는 이용고객이 회원 가입과 동시에 결제수단을 통해 결제를 허락함에 의해 성립됩니다. 월 결제 금액의 최소 한도는 월 10,000원(부가세 제외)이며, 월 결제금액의 상한은 결제수단에 따라 다를 수 있습니다.</li>
                </ol>
              </div>
              <div>
                제9조 (이용신청)
                <ol>
                  <li>이용을 원하는 고객은 약관에 동의하고 서비스 종류별 이용신청 방법에 따라 가입함으로써 효력이 발생합니다.</li>
                  <li>
                    후불제 서비스 이용을 원하는 고객은 다음 각 호의 서류를 직접 또는 우편이나 기타 회사가 인정하는 방식으로 회사에 제출하여야 합니다. 
                    <ul>
                      <li>① 법인인감 날인한 가입신청서(소정 양식)</li>
                      <li>② 법인인감 증명서(개인사업자의 경우 대표자인감 증명서)</li>
                      <li>③ 사업자등록증 사본</li> 
                      <li>④ 대표자 신분증 사본 및 통신서비스 이용증명원</li>
                      <li>⑤ 스팸메시지 관리 및 발신번호 변작 방지 동의서(소정 양식)</li>
                      <li>⑥ 개인정보 수집·이용 동의서(소정 양식)</li>
                      <li>⑦ (해당 시)월 예상발송 건수의 세 배에 해당하는 금액을 담보하는 이행(지급)보증보험증권</li>
                      <li>⑧ (해당 시)기타 요금의 감면, 할인 등을 위하여 회사가 이용고객에게 요청하는 서류</li>
                    </ul>
                  </li>

                  <li>선불제의 이용을 원하는 고객은 서비스 홈페이지에 접속하여 회원 가입에 필요한 기재사항을 작성하신 후 선불 결제 완료 후에 가입이 성립됩니다.</li>
                  <li>이용고객이 만14세 미만이거나 한정치산자인 경우에는 서비스 회원가입 및 이용신청을 할 수 없습니다.</li>
                  <li>회사는 이용고객의 업종, 이용형태 등에 따라 별도의 동의서를 요청하거나 부속계약서를 체결할 수 있으며, 이를 위하여 이용고객의 개인정보를 이용할 수 있습니다.</li>
                  <li>회사는 개인정보의 수집 시 관련법규 및 상기 2항의⑥에 따라 이용신청서에 그 수집범위 및 목적을 사전 고지하며, 회사의 서비스 홈페이지에 개인정보 처리방침을 공개합니다.</li>
                  <li>
                    회사의 개인정보처리업무 위탁에 대한 사항은 별도의 ‘개인정보처리 위탁 계약서’로 진행하며, 수탁사 및 위탁목적 등의 세부내용은 개인정보 처리방침에 공개합니다.
                  </li>
                  <li>회사는 개인정보처리방침에서 규정하고 있는 수집항목 및 이용목적 외에 불법스팸 발송 등으로 서비스 계약을 해지한 이용고객의 서비스 이용신청에 대한 승낙을 유보하기 위하여 성명, 법인, 사업자등록번호, 전화번호, 해지사유 등의 정보를 수집할 수 있습니다.</li>
                </ol>
              </div>
              <div>
                제10조 (이용신청의 승낙)
                <ol>
                  <li>회사는 이용고객에 대하여 업무 수행상 또는 기술상 지장이 없는 경우에는 접수순서에 따라 서비스 이용을 승낙합니다.</li>
                  <li>
                    회사는 이용신청을 승낙한 때에는 다음 각호의 사항을 이용고객에게 전화, 메일 등의 방법으로 통지합니다.
                    <ul>
                      <li>① 서비스 개통예정일</li>
                      <li>② 요금 등에 관한 사항</li>
                      <li>③ 이용고객의 권익보호 및 의무 등에 관한 사항</li>
                      <li>④ 기타 회사가 필요하다고 인정하는 사항</li>
                    </ul>
                  </li>
                  <li>선불제 이용고객은 선불 결제가 완료된 경우에 서비스를 즉시 이용할 수 있으며, 결제수단을 이용하여 서비스를 이용할 경우 회사가 제공하는 결제수단 별 결제방식에 동의한 것으로 간주됩니다.</li>
                </ol>
              </div>
              <div>
                제11조 (이용신청의 거절과 제한)
                <ol>
                  <li>
                    회사는 다음 각호의 1에 해당하는 이용신청에 대하여는 이를 승낙하지 아니합니다.
                    <ul>
                      <li>① 다른 사람의 명의를 사용하여 신청하는 경우</li>
                      <li>② 필수자료 및 정보를 제출하지 않거나 허위의 자료 또는 정보를 제출하는 경우</li>
                      <li>③ 전국은행연합회 내지 거래은행에 연체정보자로 등록된 자</li>
                      <li>④ 회사에 미납요금이 있거나 통신사업자 미납공동 DB에 등록된 경우</li>
                      <li>⑤ 미성년자의 경우 법정대리인의 동의 또는 승낙이 없는 경우</li>
                      <li>⑥ 기타 이용신청자의 귀책사유로 이용승낙이 곤란한 경우</li>
                      <li>⑦ 선불제 이용고객에 한해 결제 승인이 거부되었을 경우</li>
                      <li>⑧ 제31조 3항에 의하여 회사로부터 계약해지를 당한 후 1년이 경과하지 않은 경우</li>
                      <li>⑨ 발신번호 사전등록을 거부하거나 거짓으로 표시된 전화번호 이용으로 피해가 발생한 경우</li>
                    </ul>
                  </li>
                  <li>
                    회사는 다음 각호의 1에 해당하는 경우에는 그 사유가 해소될 때까지 승낙을 연기합니다.
                    <ul>
                      <li>① 회사가 제공하는 특정 모듈 및 DB 스키마를 설치함에 있어 시스템 등의 신설, 개조 또는 수리가 기술상 현저히 곤란한 때</li>
                      <li>② 전용회선 또는 인터넷회선을 통해 회사 전송시스템과 접속이 원활하지 않아 전기통신설비기준에 부적합하다고 인정될 때</li>
                      <li>③ 서비스를 제공하기 위하여 필요한 설비가 없거나 기 설치된 설비에 여유가 없는 경우</li>
                    </ul>
                  </li>
                  <li>회사는 제1항 또는 제2항의 규정에 의하여 이용신청이 거절되거나 지연될 경우에는 그 사유를 신청자에게 즉시 통지합니다.</li>
                </ol>
              </div>
              <div>
                제12조 (서비스 ID 부여) 
                <ol>
                  <li>회사는 이용계약을 승낙하는 때에는 이용고객에게 서비스 ID와 비밀번호를 부여하고 회사 시스템에 접속할 수 있도록 허용합니다.</li>
                  <li>회사는 다음 각호의 1에 해당하는 때에는 서비스 ID를 변경할 수 있습니다. 이 경우 그 사유와 변경사항을 변경 5일 전까지 이용자에게 통지합니다.</li>
                  <ul>
                    <li>① 회사의 접속시스템에 변경이 있을 때</li>
                    <li>② 기타 업무수행상 또는 기술상 필요한 때</li>
                  </ul>
                  <li>이용고객은 ID와 비밀번호를 선량한 관리자의 주의 의무로서 관리하여야 합니다.</li>
                </ol>
              </div>
              <p>제 3 절 설치 공사 및 개통</p>
              <div>
                제13조 (회사가 제공하는 특정모듈의 설치) 
                <ol>
                  <li>이용고객 측의 시스템(O/S, DB, 모듈 등)은 이용고객이 설치하여야 합니다. 다만, 이용고객이 설치를 요청할 때에는 이용고객이 비용을 부담하는 조건으로 회사가 대행할 수 있으며, 이용고객의 비용부담은 이용고객과 회사가 합의하여 결정합니다. </li>
                  <li>제1항의 규정에 의하여 설치하는 시스템 등이 전용회선(인터넷회선)을 통하여 접속하고자 할 경우에는 회사가 제공하는 접속기준에 적합하여야 합니다.</li>
                  <li>서비스의 품질개선을 위하여 필요하다고 회사가 인정하는 경우에 회사는 이용고객측 시스템에 추가/변경 모듈을 설치할 수 있습니다.</li>
                </ol>
              </div>
              <div>제14조 (운용점검) 
                <li>회사는 제13조(회사가 제공하는 특정모듈의 설치)규정에 의하여 이용고객이 설치한 시스템 등이 회사가 제공하는 접속기준에 적합한지 여부 등을 확인하기 위해 필요한 경우에는 운용 점검할 수 있습니다.</li>
              </div>
              <div>
                제15조 (서비스의 개통)
                <ol>
                  <li>
                    회사는 제10조(이용신청의 승낙) 제2항 제1호의 서비스개통 예정일에 서비스를 개통합니다. 이 경우 회사는 개통예정일 전까지 개통을 완료하고 시스템 상호간 안정성, 신뢰성 및 호환성을 확보하여야 합니다.
                  </li>
                  <li>
                    회사는 업무상 또는 기술상 특별한 지장이 없는 경우에는 청약서 접수 후 30일 이내에 서비스를 개통합니다. 다만, 이용고객이 당초 개통 희망 일을 지정하여 신청한 경우로서 그 지정일에 개통이 가능한 때에는 그 날을 개통일로 하여 승낙합니다.
                  </li>
                  <li>이용고객이 그의 사정에 의하여 이미 지정된 개통예정일을 연기하고자 하는 때에는 당초 개통예정일의 5일전까지 회사에 신청하여야 합니다.</li>
                  <li>제3항의 규정에 의하여 개통 일을 연기한 기간이 당초 개통예정일로부터 30일을 초과한 때에는 30일을 경과한 다음날부터 서비스가 개통된 것으로 봅니다. 다만, 회사의 사정 또는 회사가 인정하는 부득이한 사정에 의하여 지연된 때에는 그러하지 아니합니다.</li>
                  <li>
                    회사는 제2항의 규정에 의하여 청약서 접수 후 30일 이내 또는 개통 희망 일에 개통하지 못하였을 경우에는 즉시 그 사유와 개통 일을 다시 정하여 이를 신청자에게 유선 및 서면으로 통보합니다. 이 경우 재개통일은 그 날로부터 30일을 초과하지 아니합니다.
                  </li>
                  <li>회사는 서비스 최초 개통 시 이용고객의 예상 발송건수를 기준으로 서비스 ID 발송한도를 설정합니다.</li>
                </ol>
              </div>
              <h6>제 3 장 유 지 보 수</h6>
              <div>
                제16조 (고장신고 및 처리)
                <ol>
                  <li>이용고객은 서비스에 장애가 발생했을 때에는 이용고객 측 시스템에 장애가 없는 것을 확인한 후 회사 또는 지정된 유지보수업체에 고장신고를 하여야 합니다.</li>
                  <li>회사는 제1항의 규정에 의한 고장신고를 받아 이를 처리한 경우에는 그 결과를 이용고객에게 통지합니다.</li>
                </ol>
              </div>
              <h6>제 4 장 역 무 제 공 및 이 용</h6>
              <p>제 1 절 통 칙</p>
              <div>
                제17조 (회사의 의무)
                <ol>
                  <li>회사는 특별한 사정이 없는 한 이용고객이 신청한 서비스 개통 희망 일에 서비스를 이용할 수 있도록 합니다.</li>
                  <li>회사는 제25조 (이용의 제한) 및 제24조(서비스 이용시간) 2항의 규정에서 정한 사유 이외에는 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 의무가 있습니다.</li>
                  <li>회사는 서비스제공을 위한 시스템에 장애가 발생하거나 또는 그 시스템이 고장발생시에는 이를 최대한 신속히 수리 또는 복구합니다. 다만, 천재지변, 비상사태 또는 그밖에 부득이한 경우에는 그 서비스를 일시 중단하거나 정지할 수 있습니다.
                  </li>
                  <li>회사는 서비스 제공과 관련하여 취득한 이용고객의 정보를 본인의 사전 승낙 없이 타인에게 누설 또는 배포 할 수 없으며 상업적 목적으로도 사용할 수 없습니다. 다만, 관계법령에 의한 관계기관으로부터의 요청 등 법률의 규정에 따른 적법한 절차에 의한 경우 또는 회사가 정한 기간 동안 이용요금을 체납하여 이용고객의 동의를 얻어 신용정보사업자 또는 신용정보집중기관에 제공하는 경우에는 그러하지 않습니다.
                  </li>
                  <li>회사는 이용고객이 서비스 제공목적에 맞는 서비스 이용여부를 확인하기 위하여 상시적으로 모니터링을 실시하며, 불법스팸을 전송한 사실을 확인한 경우, 한국인터넷진흥원 불법스팸대응센터에 관련 자료를 첨부하여 신고할 수 있습니다.
                  </li>
                  <li>회사는 이용고객으로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 처리일정을 서면 또는 전화 등으로 통보하여야 합니다.</li>
                  <li>회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용고객과의 계약관련 절차 및 내용 등에 있어 이용고객에게 편의를 제공하도록 노력합니다.</li>
                  <li>
                    회사는 이용료 청구의 분쟁해결 및 공공의 질서 또는 미풍양속을 해하는 내용에 대한 경찰서, 전파관리소, 방송통신위원회 등 관계기관의 수사 협조를 위해 이용고객으로부터 전송된 메시지에 대한 전송로그를 1년 간 보관합니다. 이 기간이 경과한 메시지 로그에 대해서는 자동 삭제되며, 삭제된 로그에 대해서는 어떠한 경우에도 제공이 불가합니다.
                  </li>
                  <li>회사는 사업의 전부 및 일부를 휴지 또는 폐지 하고자 할 때에는 그 휴지 또는 폐지 예정일의 30일전까지 그 내용을 이용고객에게 이메일, 홈페이지 등을 이용하여 공지하여야 합니다.</li>
                  <li>회사는 “전기통신사업법 제 84조의 2” 및 과학기술정보통신부의 관련 (거짓으로 표시된 전화번호로 인한 피해 예방 등에 관한) 고시에 따라 발신번호 사전등록서비스를 제공 및 운영합니다.
                  </li>
                  <li>회사는 이용고객의 발신번호가 거짓표시된 인터넷 문자메시지인 경우 즉각 차단하여야 합니다.</li>
                  <li>회사는 한국인터넷진흥원으로부터 사칭방지 전화번호 목록을 받은 후 즉시 반영합니다.</li>
                  <li>회사는 ‘거짓으로 표시된 전화번호로 인한 이용자의 피해 예방 등에 관한 고시’와 관련된 과학기술정보통신부의 요청에 따라, 계약된 특수한유형의 부가통신사업자가 발송하는 문자메시지에 식별코드가 삽입되지 않은 경우 문자메시지의 발송 또는 전달을 차단할 수 있습니다.</li>
                </ol>
              </div>
              <div>
                제18조 (이용고객의 의무)
                <ol>
                  <li>이용고객은 서비스 이용에 대한 대가로서 이 약관에서 정한 요금 등을 요금납입책임자와 연대하여 납입할 의무가 있습니다.</li>
                  <li>이용고객은 서비스 계약에 필요한 개인신상정보 등을 회사에 허위로 제공하여서는 안되며, 정보변경 시 지체없이 회사에 통보하여 갱신하여야 합니다.
                  </li>
                  <li>이용고객은 회사의 서비스 제공 목적 외의 용도로 서비스를 이용하여서는 안되며, 타인의 지적재산권 또는 타인이 사생활을 침해하는 내용물을 전송할 목적으로 사용하지 아니 하고, 제3자에게 임의로 해당 서비스를 임대하여서도 안됩니다.</li>
                  <li>이용고객은 정보통신망법의 광고성 정보 전송 시 의무사항 및 회사의 이용약관을 준수하여야 하며, 기타 회사의 업무수행에 현저한 지장을 초래하는 행위를 하여서는 안됩니다.</li>
                  <li>이용고객은 공공의 안녕질서 또는 미풍양속을 해하는 다음 각호의 통신을 하여서는 안됩니다.
                    <ul>
                      <li>① 범죄행위를 목적으로 하거나 범죄행위를 교사하는 내용</li>
                      <li>② 반국가적 행위의 수행을 목적으로 하는 내용</li>
                      <li>③ 선량한 풍속, 기타 사회질서를 해하는 내용</li>
                    </ul>
                  </li>

                  <li>이용고객은 정보통신망법에 따라 SMS, MMS 또는 RCS서비스 전송을 위한 이동통신사 가입자의 사전 수신동의를 직접 얻어야 하고, 스팸 또는 불법스팸 전송을 위해 서비스를 이용하여서는 아니되며, 회사를 통해 전달되는 수신거부 요청에 대해서는 즉각적으로 처리하고 24시간 이내에 처리결과를 회사에 회신하여야 하며 본 항의 위반으로 발생하는 모든 민·형사상의 책임은 이용고객이 직접 부담합니다.</li>
                  <li>이용고객은 재화 및 용역의 거래관계를 통하여 수신자로부터 직접 연락처를 수집한 경우에는 수신자의 사전 동의 없이 이용고객이 취급하는 재화 및 용역에 대한 영리목적의 광고성 정보를 전송할 수 있습니다. 다만, 이 경우의 기존거래관계는 광고수신일자로부터 최근 6개월 이내에 거래관계가 있는 경우에만 인정합니다.</li>
                  <li>전기통신사업법 제84조의2(전화번호의 거짓표시 금지 및 이용자 보호)에 의거하여, 이용고객은 발신번호를 회사의 서비스 홈페이지 또는 회사가 지정하는 시스템에 사전 등록하여야 하며, 사전등록된 전화번호로만 서비스를 이용하여야 합니다.
                  </li>
                  <li>
                    이용고객은 다음 각 호의 1에 해당하는 경우에는 회사의 요구에 따라 월 이용요금의 3배수에 해당하는 금액을 보험가입금액으로 하는 이행(지급)보증보험증권(이하 "증권")을 제출하여야 합니다.
                    <ul>
                      <li>① 서비스 이용 신청시 1개월 이용요금이 삼백만원 이상이 예상되는 경우</li>
                      <li>② 서비스 이용 중에 발송량이 증가하여 해당월 이용요금이 삼백만원 이상이 되는 경우</li>
                      <li>③ 기존의 이용고객중 최근 3개월 월 이용요금의 최대금액이 삼백만원 이상인 경우</li>
                    </ul>
                  </li>
                  <li>이용고객이 증권을 제출하였더라도 청구될 월 이용요금이 보험가입금액의 1/2을 초과하게 될 경우에는 초과된 금액의 2배수에 해당하는 금액만큼 보험가입금액을 증액하여 증권을 제출하여야 합니다. 
                  </li>
                  <li>이용고객은 보험기간이 만료되기 7일전까지 기간을 연장하여 제출해야 하며, 이 때도 상기 9항의 조건을 준용하여야 합니다.</li>
                  <li>회사의 요구에도 불구하고 이용고객이 증권을 미제출 할 경우 회사는 서비스 제공을 정지할 수 있으며, 이때 절차는 제25조(이용제한 절차)에 명시된 내용에 따릅니다.</li>
                </ol>
              </div>
              <div>
                제19조 (이용고객의 책임)
                <ol>
                  <li>서비스 ID 및 비밀번호의 관리 및 이용은 이용고객의 책임으로 합니다.</li>
                  <li>서비스 ID는 다음 각호의 1에 해당하는 경우에는 이용고객의 요청에 의하여 변경할 수 있습니다.
                    <ul>
                      <li>① 서비스 ID가 이용고객의 전화번호 또는 주민등록번호 등으로 되어있어 사생활 침해가 우려되는 경우</li>
                      <li>② 타인에게 혐오감을 주거나 미풍양속에 어긋나는 경우</li>
                      <li>③ 기타 합리적인 사유가 있는 경우</li>
                    </ul>
                  </li>
                  <li>이용고객의 서비스 ID 또는 비밀번호에 의하여 발생되는 사용상의 과실 또는 제3자에 의한 부정사용 등에 대한 모든 책임은 이용고객에게 있습니다. 다만, 회사의 고의 또는 중과실이 있는 경우에는 그러하지 아니합니다.
                  </li>
                  <li>이용고객은 SMS 또는 MMS 발송을 위해 이용고객이 운영하는 어플리케이션, 웹서버, DB서버, 네트웍 등에 대한 관리의 책임을 가지며, 이용고객의 관리 소홀 또는 시스템 취약점에 의한 해킹 또는 불법 침해로 인해 발생되는 서비스 중단, 불법 스팸 발송 등의 피해에 대해서는 회사가 책임지지 않습니다.
                  </li>
                  <li>이용고객이 한국인터넷진흥원 또는 이동통신사를 통해 번호도용 문자차단 서비스를 신청한 경우, 이용고객은 반드시 해당 전화번호와 회사에 대한 정보를 현행화 하여야 책임이 있습니다.</li>
                </ol>
              </div>
              <div>
                제20조 (이용고객의 개인정보 보호)<br />
                회사는 관련 법령이 정하는 바에 따라 이용고객이 서비스 신청 또는 계약 변경 시 등록한 정보를 포함한 개인정보를 보호하기 위하여 노력합니다. 이용고객의 개인정보보호에 관해서는 관련 법령 및 회사가 정하는 ‘개인정보처리방침’을 따릅니다. 
              </div>
              <div>
                제21조 (개인정보의 취급위탁) <br />
                회사는 수집된 개인정보의 취급 및 관리 등의 업무를 스스로 수행함을 원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를 회사가 선정한 제3자에게 위탁할 수 있으며, 이 경우 회사는 개인정보보호법 등 관련 법령을 따릅니다.
              </div>
              <p>제 2 절 기본 서비스</p>
              <div>
                제22조 (기본서비스의 제공) 
                <ul>
                  <li>회사는 이용고객의 이용신청 승낙 후 서비스 개통시점부터 기본서비스를 제공합니다</li>
                </ul>
              </div>
              <div>
                제23조 (기본서비스 종류) 
                <ol>
                  <li>기본 서비스의 종류는 제6조(서비스의 종류)와 같습니다.</li>
                  <li>회사는 필요에 따라 제1항의 규정에 의한 서비스 종류를 제한 또는 변경할 수 있습니다.</li>
                </ol>
              </div>
              <div>
                제24조 (서비스 이용시간)
                <ol>
                  <li>서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.
                  </li>
                  <li>정기점검 등과 같이 회사가 서비스 운영에 필요한 해당일은 제24조(서비스 이용시간) 제1항에서 제외되며, 회사는 사전에 이용고객에게 사유와 해당일 등 중요 정보를 전달해야 합니다.</li>
                </ol>
              </div>
              <h6>제 5 장 이 용 제 한</h6>
              <div>
                제25조 (이용의 제한)
                <ol>
                  <li>회사는 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.</li>
                  <li>회사는 이용고객의 서비스 이용내용이 다음 각호에 해당할 경우 서비스 이용을 제한할 수 있습니다.
                    <ul>
                      <li>① 제18조 규정에 의한 이용고객의 의무를 이행하지 않은 경우</li>
                      <li>② 이용고객이 지정된 요금납기일로부터 5영업일 이내에 청구된 요금을 납입하지 않거나, 휴폐업, 사망 또는 월 발생 이용요금이 담보가액을 초과한 경우</li>
                      <li>③ 서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 수신자의 의사에 반하여 광고성 정보를 지속적으로 전송하는 경우</li>
                      <li>④ 정보통신설비의 오 동작이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우</li>
                      <li>⑤ 다른 이용고객 또는 제3자의 지적재산권을 침해하는 경우</li>
                      <li>⑥ 정보통신윤리위원회의 시정요구가 있거나, 불법선거운동과 관련하여 선거관리위원회의 유권해석을 받은 경우</li>
                      <li>⑦ 타 이용고객의 서비스 ID를 부정하게 이용하거나 타인의 명예를 손상시키거나 불이익을 주는 경우</li>
                      <li>⑧ 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 경우</li>
                      <li>⑨ 서비스에 위해를 가하거나 서비스의 건전한 이용을 저해하는 경우</li>
                      <li>⑩ 타인의 명의를 이용하여 신청한 경우, 신청서의 내용을 허위로 기재 하였거나 허위서류를 첨부하여 서비스 이용승낙을 득한 경우</li>
                      <li>⑪ 국익 또는 사회적 공익을 저해할 목적으로 이용되는 경우</li>
                      <li>⑫ 광고의 수신자가 스팸으로 신고하거나 이용고객이 발송하는 메시지가 불법스팸임이 판명될 경우</li>
                      <li>⑬ 회사의 수신거부 요청 처리에 불성실하여 수신거부 요청 건수가 감소되지 않거나 발송금지를 요청한 메시지 내용이 중복적으로 발송될 경우</li>
                      <li>⑭ 방송통신위원회 또는 한국인터넷진흥원이 불법스팸 전송사실을 확인하여 이용정지를 요청하는 경우</li>
                      <li>⑮ 과학기술정보통신부(거짓으로 표시된 전화번호로 인한 이용자 피해 예방에 관한) 고시에 따라 과학기술정보통신부, 중앙전파관리소 또는 한국인터넷진흥원(KISA) 등 관련기관에서 발신번호 거짓표시 등으로 판명되어 이용 정지를 요청한 경우</li>
                      <li>⑯ 미청구 요금과 미수금액 합계가 담보금액의 90%를 초과한 경우</li>
                      <li>⑰ 이통3사(SKT, KT, LGU+)에 LMS발송 비율 중 당사(LGU+)의 비율이 과학기술정보통신부 무선통신서비스 가입회선 통계상의 비율 2배를 초과할 경우</li>
                      <li>⑱ 정보통신망법 제50조의4 제1항 및 제 4항에 의하여 서비스 제공 거부 사유가 발생하는 경우</li>
                      <li>⑲ 선불제 이용고객이 선결제한 금액이 모두 소진된 경우</li>
                    </ul>
                  </li>
                  <li>회사는 상기 2항 ⑪, ⑫, ⑬, ⑭, ⑮, ⑯, ⑰, ⑱의 경우 이용고객에게 서비스 발송량 축소, 서비스ID수 축소, 서비스 ID 정지 등의 방법으로 사전통보 없이 서비스를 제한할 수 있습니다.</li>
                  <li>회사는 상기 2항 ⑰의 경우 이용고객에게 사실확인을 위한 이통사별 LMS 비율, 발생원인, 발송구조에 대해 소명 요청을 할 수 있습니다.</li>
                  <li>회사는 상기 2항 ⑮의 경우 이용자 회선 또는 서비스를 최대 30일간 정지할 수 있습니다. 단 악의적인 목적없이 이용자의 실수로 인해 변작된 경우 이용자의 소명을 받고 심사 후 서비스를 재개할 수 있습니다.</li>
                  <li>이용고객은 개인 사유에 의해 서비스 일시 이용중단을 회사에 요청할 수 있습니다. 이 때 서비스 일시이용중단의 기간은 1회 최대 90일을 넘지 아니합니다. 또한 회사는 서비스 일시이용 정지 기간에 이용고객에게 기본료 또는 최저료를 부과하지 않습니다.</li>
                  <li>회사는 해킹에 따른 이용고객의 피해를 최소화 하기 위해 이용고객의 발송량을 모니터링 할 수 있으며, 이를 바탕으로 이용고객의 서비스 ID 발송한도를 조정 및 제한 할 수 있습니다.</li>
                </ol>
              </div>
              <div>
                제26조 (이용제한 절차)
                <ol>
                  <li>회사는 제25조의 규정에 의하여 이용제한을 하고자 하는 경우에는 그 사유, 일시 및 기간을 정하여 이용정지 7일전까지 서면 또는 전화 등의 방법에 의하여 해당 이용고객 또는 대리인에게 통지합니다. 다만, 긴급하게 이용을 정지할 필요가 있다고 인정하는 경우 또는 이용고객의 귀책사유로 통보가 불가능한 경우, 제25조 제2항 제2호의 경우에는 사전통지 없이 서비스 제한 또는 중단 할 수 있습니다.</li>
                  <li>제1항의 규정에 의하여 이용정지 통지를 받은 이용고객 또는 그 대리인은 그 이용정지의 통지에 대하여 이의가 있을 때에는 이의신청을 할 수 있습니다.</li>
                  <li>회사는 제2항의 규정에 의한 이의신청에 대하여 그 확인을 위한 기간까지 이용정지를 일시 연기할 수 있으며, 그 결과를 이용고객 또는 그 대리인에게 통지합니다.</li>
                  <li>회사는 이용정지 기간 중에 그 이용정지사유가 해소된 것이 확인된 경우에는 이용정지조치를 즉시 해제합니다.</li>
                  <li>회사의 귀책사유가 없는 서비스 이용의 제한으로 인해 이용고객에게 손해가 발생한 경우, 회사는 책임을 지지 않습니다.</li>
                </ol>
              </div>
              <h6>제 6 장 계약변경 및 해제·해지</h6>
              <div>
                제27조 (계약의 갱신) 
                <ol>
                  <li>이용고객은 계약사항 중 다음 각호의 1에 해당하는 사항을 변경하고자 하는 때에는 서비스 변경신청서를 회사에 제출하여야 합니다.
                    <ul>
                      <li>① 이용고객 및 요금납입책임자의 상호, 성명 또는 주소의 변경</li>
                      <li>② 서비스의 종류 및 이용계약기간의 변경</li>
                    </ul>
                  </li>
                  <li>회사는 다음 각호의 1에 해당하는 경우에는 제1항의 규정에 의한 계약사항의 변경 신청을 제한할 수 있습니다.
                    <ul>
                      <li>① 회사가 제공하는 특정 모듈 및 DB 스키마를 설치함에 있어 시스템 등의 신설, 개조 또는 수리가 기술상 현저히 곤란할 때</li>
                      <li>② 전용회선 또는 인터넷회선을 통해 회사의 전송시스템과 접속이 원활하지 않아 전기통신설비기준에 부적합하다고 인정될 때</li>
                      <li>③ 서비스를 제공하기 위하여 필요한 설비가 없거나 기 설치된 설비에 여유가 없을 때</li>
                      <li>④ 요금을 체납하고 있을 때</li>
                    </ul>
                  </li>
                  <li>제1항 제2호에 의하여 이용계약이 변경된 경우에는 새로운 이용계약에 의하여 요금이 적용됩니다.</li>
                </ol>
              </div>
              <div>
                제28조 (양도 등의 금지) 이용고객의 서비스를 제공받는 권리는 제 30조의 규정에 의하여 승계하는 경우를 제외하고는 이를 양도하거나 증여 등을 할 수 없으며 또한 질권의 목적으로도 사용할 수 없습니다.
              </div>
              <div>
                제29조 (계약의 갱신) 정기이용계약 만료시 30일전까지 새로운 이용계약의 체결이 없으면 이용고객 또는 회사의 특별한 의사표시가 있을 때까지 이전의 이용계약과 동일한 조건으로 이용계약기간이 연장되는 것으로 봅니다.
              </div>
              <div>
                제30조 (이용고객의 지위승계) 상속, 합병, 분할, 영업양수 등으로 이용고객의 지위승계 사유가 발생한 때에는 그 사유가 발생한 날로부터 3개월 이내에 사업자등록증사본과 지위승계를 입증할 수 있는 관련서류를 첨부하여 회사에 신고하여야 합니다.
              </div>
              <div>
                제31조 (이용계약의 해제 및 해지) 
                <ol>
                  <li>신청자가 서비스 제공 개시 전에 이용계약을 해제하고자 하거나 서비스 제공 개시 후에 이용계약을 해지하고자 할 때에는 해제 또는 해지하고자 하는 날의 5일전까지 해지/일시정지/정지해제 신청서를  회사에 제출하여야 합니다.</li>
                  <li>회사는 제1항의 규정에 의하여 해지신청이 접수되면 이용고객이 요청한 해지일로부터 서비스 이용을 제한합니다. 이 경우 이용고객은 이용이 제한된 날까지의 요금을 완납하여야 합니다.</li>
                  <li>회사는 제25조 및 제26조에 따른 서비스 이용정지기간 경과 후 1개월 이내에 그 사유를 해소하지 아니한 경우, 정당한 사유 없이 의견진술에 응하지 않은 경우에는 이용계약을 해지 또는 해제합니다.</li>
                  <li>이용고객이 요금납기일로부터 30일이 경과할 때까지 요금을 납부하지 아니하거나, 휴폐업, 회생, 파산 절차를 신청 또는 개시한 경우, 회사는 이용계약을 즉시 해지할 수 있습니다.</li>
                  <li>서비스 정지가 불가한 경우 미납이용고객이 요금납기일로부터 60일이 경과할 때까지 요금을 납부하지 아니한 경우 회사는 이용계약을 즉시 해지할 수 있습니다. </li>
                  <li>제3항에 의해 해제, 해지된 이용고객에 대하여는 귀책사유가 해소될 때까지 가입을 제한할 수 있으며, 이용신청에 대한 승낙을 유보하기 위하여 성명, 법인 사업자등록번호, 전화번호, 해지사유 등의 정보를 1년간 보관할 수 있습니다.</li>
                  <li>이용고객이 서비스 이용 일시이용 중단을 요청한 경우 90일이 경과하였을 때, 회사는 이용고객에 대한 직권해지 조치를 취할 수 있습니다. 다만 회사는 직권해지 7일 전 고객에게 직권해지 사실을 통보하여야 합니다. 이 때 이용고객이 일시이용 중단상태 연장의사를 밝힐 경우 일시이용 중단 기간이 90일 연장되며, 의사를 밝히지 않을 시 회사는 이용고객이 직권해지에 대해 동의한 것으로 간주하여 직권해지를 집행합니다.</li>
                  <li>회사 서비스 해지 후 90일 내 재가입할 경우, 회사는 해당 이용고객을 신규고객이 아닌 기존고객으로 분류하여 관리합니다. 이 때 이용고객은 재가입한 날짜가 아닌, 이전 가입일자에 준하여 관리됩니다. 회사는 90일 내 재가입 고객의 기존고객 분류를 위해 필요한 최소한의 정보(이전가입일자 등)를 보유할 수 있으며, 이용고객은 해당하는 경우 장기이용 등의 요금할인제도를 지속적으로 적용받을 수 있습니다.</li>
                </ol>
              </div>
              <h6>제 7 장 요 금 등</h6>
              <p>제 1 절 통 칙</p>
              <div>
                제32조 (요금의 종류)
                <ol>
                  <li>서비스 이용과 관련하여 이용고객이 납입하여야 할 요금의 종류는 다음과 같습니다.
                    <ul>
                      <li>① 기본료: 이용계약 시 선택한 요금제의 매월 정기적으로 납입하는 요금</li>
                      <li>② 커스터마이징 (Customizing)비용 : 회사가 이용고객의 고유 환경에 최적화된 서비스 어플리케이션을 개별 맞춤 개발하는데 발생되는 비용 </li>
                      <li>③ 이용료: 서비스를 이용하는 대가로 월 기본료 와 추가 이용금액을 합산하여 이용고객이 매월 회사에 납입하는 요금
                      </li>
                      <li>④ 최저료: 서비스를 이용하는 고객에 대한 사용금액의 할인 적용 시, 매월 최소한 납부하여야 하는 이용료. </li>
                      <li>⑤ 선불제 요금 : 서비스를 이용하기 위한 고객이, 선결제하여 서비스를 이용하는 수단으로 이용하는 요금제도</li>
                    </ul>
                  </li>
                  <li>전기통신사업법 제22조의4에 따라, 회사는 사전에 과학기술정보통신부에 제출한 신고가를 기준으로 요금을 부과합니다. 회사의 요금신고내역은 U+기업메시징 홈페이지 및 [별표 1]에서 확인할 수 있습니다.
                  </li>
                </ol>
              </div>
              <div>
                제33조 (이용요금의 계산방법) 
                <ol>
                  <li>제32조(요금의 종류) 제1항의 규정에 의한 요금의 계산방법은 다음과 같습니다.
                    <ul>
                      <li>① 커스터마이징(Customizing)비용: 서비스의 이용승낙 또는 변경을 요청 받은 경우 회사와 이용고객간 사전 합의에 의해 정합니다.</li>
                      <li>② 이용료: 매월 1일부터 말일까지 1개월간 이용고객이 발송한 메시지 성공건수에 따라 계산됩니다. 단, 성공건수는 문자 수신자가 속한 이통사에서 전달하는 성공값을 기준으로 합니다. 또한 선불제는 예외로 합니다.</li>
                    </ul>
                  </li>
                  <li>이용료는 각 서비스의 성공건수당 단가를 계산하여 기본료와 합산합니다.</li>
                  <li>개통월 또는 해지월의 경우 이용기간이 1개월 미만인 경우에는 기본료를 제외한 해당월 사용한 발송량에 해당하는 요금을 청구 합니다.</li>
                  <li>제27조(계약사항의 변경) 3항에 의거 서비스 이용요금이 변경되는 경우 익월 1일부터 변경된 요금이 적용됩니다.</li>
                  <li>제40조(요금의 할인) 및 제 41조(요금의 반환/환불)에 따라 할인 또는 반환/환불을 받는 경우에는 할인 또는 반환/환불된 금액을 월 이용료로 합니다.</li>
                  <li>메시징 수신 서비스의 개통일, 변경일 또는 해지일이 1개월 중인 경우 기본료는 일할 계산되어 청구됩니다.</li>
                </ol>
              </div>
              <p>제 2 절 요금 등의 청구 및 납입</p>
              <div>
                제34조 (요금의 납입청구)
                <ol>
                  <li>회사는 요금의 납입청구서를 회사가 별도로 정한 납기일 7일 전까지 요금납입책임자에게 도달되도록 발송합니다. 다만, 즉납에 의한 요금의 경우에는 그러하지 아니합니다.</li>
                  <li>회사는 제1항의 규정에 의한 납입청구서가 요금납입책임자, 그 가족 또는 대리인에게 송달된 사실을 증명할 수 없을 때에는 요금의 체납에 대한 가산금의 부과나 기타 불이익 한 결정을 하지 아니합니다.
                  </li>
                  <li>회사는 요금의 납입책임자가 국가기관, 지방자치단체, 주한외국기관 또는 회사가 인정하는 경우에는 해당 기관의 상급기관 또는 해당 기업의 본사 지불로 일괄하여 납입하게 할 수 있습니다.
                  </li>
                  <li>회사는 미납금을 최근 청구 월 요금에 합산하여 청구할 수 있습니다.</li>
                  <li>회사는 요금에 따라 익월에 합산 청구하거나 일정액 이하의 소액요금의 경우에는 누적하여 청구할 수 있습니다.</li>
                </ol>
              </div>
              <div>
                제35조 (요금납입책임자) 
                <ol>
                  <li>
                    요금납입책임자는 서비스 이용고객을 원칙으로 합니다. 다만, 각호의 경우에는 서비스 이용고객 이외의 자를 요금납입책임자로 할 수 있습니다.
                    <ul>
                      <li>① 법인등기부등본상의 동일기관인 경우</li>
                      <li>② 법령에서 규정하는 공동관계에 해당하는 경우</li>
                      <li>③ 특수한 유형의 부가통신사업자가 그 이용자에 대하여 요금납입책임자로 된 경우</li>
                      <li>④ 기타 회사가 인정하는 경우</li>
                    </ul>
                  </li>
                  <li>제1항의 단서규정에 의한 요금납입책임자는 이용고객이 서비스 이용료 등 이 약관에 따른 납입의무를 이행하지 못한 경우 이를 납입할 책임이 있습니다.</li>
                </ol>
              </div>
              <div>
                제36조 (요금의 납입의무)
                <ol>
                  <li>요금납입책임자는 요금의 납입청구를 받은 때에는 청구서에 기재된 기일까지 회사가 지정하는 장소에 그 요금을 납입하여야 합니다.</li>
                  <li>이용고객이 제25조의 규정에 의하여 서비스 이용제한이 된 경우 해당기간중의 요금을 면제합니다.</li>
                </ol>
              </div>
              <p>제 3 절 면탈 및 체납요금 등의 징수</p>
              <div>
                제37조 (불법면탈요금의 징수)
                <ol>
                  <li>회사는 이용고객이 이 약관의 규정을 위배하여 서비스의 요금을 면탈한 행위를 한 경우에는 면탈한 금액의 2배에 해당하는 금액을 징수합니다.
                  </li>
                  <li>제1항의 규정에 의한 면탈요금의 징수에 관하여는 제34조(요금의 납입청구) 및 제36조(요금의 납입의무) 규정을 이에 준용합니다.</li>
                  <li>제2항의 규정에 의하여 면탈한 요금 등을 청구하는 경우에는 회사는 그 사유를 명시합니다.</li>
                </ol>
              </div>
              <div>
                제38조 (체납요금 등의 독촉)
                <ol>
                  <li>회사는 요금 등을 체납한 이용고객에게는 독촉장을 발부하여 최고합니다.</li>
                  <li>회사는 제1항의 규정에 의하여 최고할 때에는 독촉장 발부시 납입 기일을 다시 지정합니다.</li>
                  <li>이용고객이 2개월 이상의 이용요금을 미납한 경우, 회사는 제18조 9항에 의거 이용고객이 제출한 이행(지급)보증보험증권을 이용해 해당 보증보험사에 이용요금을 청구할 수 있습니다.</li>
                </ol>
              </div>
              <div>
                제39조 (가산금의 부과)
                <ol>
                  <li>이용고객이 청구서에 지정된 기일까지 요금을 납부하지 않은 때에는 그 요금의 100분의 2에 상당한 요금을 가산금으로서 납부하여야 합니다.</li>
                  <li>제1항의 규정에 의한 가산금은 요금의 납기일이 만료되는 날의 다음날을 기준으로 하여 체납된 요금에 가산하여 징수합니다. 다만, 요금의 납입의무자가 주한외국공관, 국제연합기관인 경우에는 가산금을 부과하지 아니합니다.
                  </li>
                </ol>
              </div>
              <p>제 4 절 요금 등의 감면, 할인 및 반환</p>
              <div>
                제40조 (요금의 할인)
                <ol>
                  <li>제32조에 따른 요금은 일정 요건에 따라 할인을 적용할 수 있으며, 해당 조건, 요율 등은 [별표 1]에 규정된 바와 같습니다.</li>
                </ol>
              </div>
              <div>
                제41조 (요금의 반환/환불)
                <ol>
                  <li>회사는 회사의 귀책사유에 의하여 이용고객이 계속 24시간이상 서비스를 이용할 수 없게 된 경우에 그 사실을 회사에 통보하여 확인되거나 또는 회사가 알게 된 날로부터 계산하여 서비스 이용이 가능하게 된 날의 전날까지의 일수에 상당한 서비스 이용료를 반환합니다.
                  </li>
                  <li>회사는 이용고객의 과오납액에 대해서는 이를 반환합니다. 또한, 회사의 과소 청구액에 대해서는 다음달에 합산하여 청구합니다.</li>
                  <li>회사는 제1항 및 제2항의 규정에 의해 반환하여야 할 요금을 다음달에 청구하는 요금에서 상계할 수 있습니다. 이 경우 회사는 그 뜻을 미리 이용고객에게 통지합니다.</li>
                  <li>회사는 제1항 및 제2항의 규정에 의하여 요금을 반환하여야 할 이용고객에게 요금 등의 체납이 있을 경우에는 반환하여야 할 요금에서 이를 우선 공제하고 반환합니다.</li>
                  <li>선불제 이용고객에 대해서는 다음 각 호에 해당하는 경우 환불할 수 있습니다.
                    <ul>
                      <li>① 선불 충전을 완료했으나, 서비스 사용에 대한 내용이 전무하여 그에 대한 책임이 전적으로 회사에 있는 경우(단, 서비스 점검이 불가피한 경우 및 사전에 공지한 경우 등은 제외)</li>
                      <li>② 선불 충전을 시도하였으나, 충전금액이 정상적으로 충전되지 못하여 환불을 요청한 경우</li>
                      <li>③ 기타 소비자 보호를 위해 회사에서 따로 정하는 경우</li>
                      <li>④ 회사의 귀책사유로 이용자에게 24시간 이상 연속적으로 서비스를 제공하지 못하거나 중단하는 경우 회사가 정한 규칙에 의거하여 과실에 대한 환불조치를 합니다. </li>
                    </ul>
                  </li>
                  <li>본조 5항에 따라 선불제 요금의 환불절차는 다음과 같습니다.
                    <ul>
                      <li>① 본조 5항의 이유로 환불을 원하는 이용고객은 회사의 “고객센터(1544-5992)” 또는 “콜센터”를 통하여 신청해야 하며, 회사는 환불 신청이 정당함을 심사한 후, 정당한 이유가 있음으로 판명된 이용고객에게 환불합니다.</li>
                      <li>② 제41조 (요금의 반환/환불) 1항에 의한 이유로 이용고객이 환불을 신청하면, 남은 잔액 건 수에서 재정경제부에서 고시한 &lt;인터넷이용관련소비자피해보상&gt;에서 규정한 위약금(총 이용료의 10%) 및 PG수수료, 송금비용을 공제한 이용료 잔액을 환불하여 드립니다. 단, 위약금 및 PG수수료, 송금비용의 합계는 최소 1,000원입니다.</li>
                    </ul>
                  </li>
                  <li>회사는 선불제 이용고객의 휴면 ID 정보와 환불 안내 정보를 문자메시지 또는 이메일 등으로 2회 이상 이용고객에게 알려야 합니다.</li>
                  <li>선불제 이용고객은 회사에게 휴면 ID 정보를 통지받은 날로부터 30일 이내에만 환불을 요청할 수 있습니다.</li>
                </ol>
              </div>
              <div>
                제42조 (이의신청)
                <ol>
                  <li>청구된 요금에 이의가 있는 경우, 요금 납입책임자는 청구일로부터 1년 이내에 회사에 이의신청을 하여야 합니다.</li>
                  <li>회사는 상기 제1항에 의한 이의신청에 대하여 타당성 여부를 조사하고 그 결과를 이의신청 접수 후 10일 이내에 요금납입책임자 또는 그 대리인에게 서면 또는 전화로 통보합니다.</li>
                  <li>부득이한 사유로 제2항에서 정한 기간 내에 이의신청 결과를 통지할 수 없는 경우에는 그 사유 및 재지정 처리기간을 명시하여 이를 요금납입책임자 또는 그 대리인에게 통지합니다.
                  </li>
                </ol>
              </div>
              <h6>제 8 장 손 해 배 상 등</h6>
              <div>
                제43조 (손해배상의 범위)
                <ol>
                  <li>이용고객의 책임없는 사유로 서비스를 이용하지 못하는 경우에 그 뜻을 회사에 통지하여 확인한 때(그 전에 회사가 그 사실을 알거나 알 수 있게 된 때)로부터 4시간 이상 서비스를 제공받지 못하거나 월 장애누적시간이 12시간을 초과한 경우에 대하여 이용고객의 최근 3개월(3개월 미만인 경우에는 해당기간 적용)의 1일 평균이용요금에 서비스 제공정지시간을 24로 나눈 수를 곱하여 산출한 금액의 3배를 배상하며, 이 경우 단수가 1시간 미만인 경우에는 1시간으로 합니다.
                  </li>
                  <li>회사의 귀책사유로 이용고객이 서비스를 이용하지 못하는 경우, 회사는 이용고객으로부터 서비스를 이용하지 못한 사실을 접수한 시간(또는 회사가 인지한 시간)부터 서비스 재개를 위한 가능한 조치를 취하며, 서비스를 다시 이용할 수 있게 된 경우 이 사실을 이용고객에게 통지합니다. 다만, 이용고객이 서비스 이용불가 사실을 회사에 접수한 이후 3시간 이내 서비스가 정상화된 경우는 제외합니다.
                  </li>
                  <li>회사는 상기 규정에도 불구하고 다음 각호의 1의 사유를 입증하는 경우에는 요금 감면 또는 손해배상 책임이 감면될 수 있습니다.
                    <ul>
                      <li>① 전시, 사변, 천재지변 또는 이에 준하는 국가 비상사태등 불가항력으로 인한 경우</li>
                      <li>② 이용자의 고의 또는 과실로 인하여 발생한 경우</li>
                      <li>③ 전기통신서비스의 특성상 불가피한 사유로 서비스 제공이 불가능한 경우</li>
                      <li>④ 어플리케이션의 장애가 개별 이용고객에게 국한되었을 경우</li>
                    </ul>
                  </li>
                  <li>이용고객이 책임 없는 사유로 서비스를 이용하지 못한 사실을 회사가 인지한 경우에도 이용자가 서비스를 이용하지 못한 사실을 회사에 통보한 것과 동일하게 간주하여 보상합니다.</li>
                  <li>회사는 이용고객이 서비스를 이용할 수 없다고 통지한 일자 및 시간, 서비스 재개를 위한 회사의 조치내역과 서비스 재개시점에 관한 사실을 기록할 수 있도록 별도의 이용고객 불만 접수 및 처리대장을 비치, 관리합니다.</li>
                  <li>손해배상 대상에는 회사와 서비스 이용계약이 체결된 서비스 내용에 한합니다. 다만, 회사와 이용고객이 별도의 손해배상 범위를 합의한 경우에는 그 합의한 내용에 따릅니다.</li>
                  <li>손해배상금액은 최근 3개월(3개월 미만인 경우에는 해당기간 적용)의 평균요금에 12를 곱하여 산출한 금액을 상한선으로 합니다.</li>
                  <li>발신번호 사전등록 불이행 및 거짓표시된 전화번호 사용, 번호도용 문자차단 서비스 등록에 따른 전송차단 발생 등 이용고객이 관련 법제도를 이행하지 않아 발생한 손해에 대해 회사는 손해배상하지 않습니다.</li>
                </ol>
              </div>
              <div>
                제44조 (손해배상의 청구)
                <ol>
                  <li>손해배상의 청구는 회사에 청구사유, 청구금액 및 산출근거를 기재하여 서면으로 하여야 합니다.</li>
                  <li>제1항의 손해배상 청구권은 청구사유가 발생한 날로부터 만 3개월이후 소멸됩니다. 다만, 그 이전에 이용고객의 손해배상 청구가 접수된 경우는 제외합니다.</li>
                  <li>회사는 이용고객의 손해배상 청구 접수후 6일이내 그 결과를 이용고객에게 회신합니다. 다만, 관련 사실 확인기간이 6일이상 소요되는 경우, 회신 가능일자를 이용고객에게 통지하여야 하며, 그 일자는 30일을 넘지 않아야 하며, 이용고객과 별도 합의한 경우에는 합의된 내용에 따릅니다.
                  </li>
                </ol>
              </div>
              <div>
                제45조 (이용고객에 대한 손해배상 청구)
                <ol>
                  <li>이용고객이 제18조, 제19조에서 규정한 의무사항을 위반하여 회사에 손해를 끼친 경우에 회사는 해당 이용고객에 대해 손해배상을 청구할 수 있습니다.</li>
                  <li>회사가 이용고객에 대해 손해배상을 청구하는 경우의 절차에 대해서는 제45조의 규정을 준용합니다.</li>
                  <li>이용고객이 번호도용 문자차단 서비스를 현행화하지 않아 회사에 행정처분(과태료 등)이 발생한 경우 회사는 이용고객에게 손해배상을 청구할 수 있습니다.</li>
                </ol>
              </div>
              <div>
                제46조 (면책)
                <ol>
                  <li>이용고객이 회사의 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스로부터 수반되는 잠재가치 및 서비스 자료에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대해서는 책임이 면제됩니다. 
                  </li>
                  <li>회사는 이용고객의 귀책사유로 인하여 서비스 이용의 장애가 발생한 경우에는 책임이 면제됩니다.</li>
                  <li>회사의 책임은 물리적인 서비스에 한하며, 특히 이용고객이 게시 및 보관 또는 전송한 자료의 내용에 대해서는 책임이 면제됩니다.</li>
                  <li>회사는 이용고객 상호간 또는 이용고객과 제3자 상호간에 서비스를 매개로 하여 물품거래 등을 한 경우 해당 거래로 인해 발생하는 손실에 대해 책임을 지지 않습니다.</li>
                  <li>회사는 이용고객과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.</li>
                  <li>정보통신망법에서 규정한 사항을 위반하여 발생한 손해에 대한 책임은 회사에서 책임을 지지 않습니다. 또한 회사가 물질적 정신적 손해를 입을 경우, 이용고객은 회사가 입은 손해에 대한 배상 책임 의무가 있습니다.</li>
                  <li>회사는 제25조(이용의 제한)에 의거 이용고객의 서비스 이용을 제한하는 경우, 이용의 제한으로 발생할 수 있는 이용고객의 손해 등에 대해서는 책임이 면제됩니다
                  </li>
                </ol>
              </div>
              <div>
                제47조 (M-Gov서비스 제공)
                <ol>
                  <li>회사는 정부통합전산센터의 M-Gov서비스와 관련하여 기술지원을 제외한 포괄적 업무를 수행하며, 그 업무에는 이동통신사와의 계약, 서비스 신청접수, 이용료 청구/수납, 고객센터 업무를 포함합니다. 단, M-Gov서비스 사용방법, 제공내용 및 사용조건과 이용료 청구/수납, 고객센터업무 및 보수유지는 회사와 M-Gov서비스를 주관하는 정부통합전산센터와 별도 체결되는 약정이 정하는 바에 따릅니다.</li>
                </ol>
              </div>
              <div>
                제48조 (회사의 의무)
                <ol>
                  <li>회사는 이용자의 요금연체 정보를 신용정보의 이용 및 보호에 관한 법률 제15조의 신용정보회사등에 제공하기 전에 이용자 본인(법인 고객은 제외)에게 연체정보 제공 사실을 알립니다</li>
                </ol>
              </div>

              <h6>제9장 개인정보처리업무 위탁에 관한 사항</h6>

              <p>제1절 개인정보처리업무 수탁자인 회사의 의무</p>
              <div>
                제47조 (위탁업무의 목적 및 범위)
                <ol>
                  <li>이용고객은 이용고객이 개인정보처리자로서 수행하는 본 조 제2항의 개인정보처리 업무를 회사에게 위탁하고 회사는 이를 승낙하여 성실하게 업무를 이행합니다.</li>
                  <li>회사는 서비스 제공 목적으로 문자메시지 발송을 위한 개인정보(휴대폰번호, 메시지 내용 등) 처리 업무(이하 “수탁업무”)를 수행합니다.</li>
                </ol>
              </div>
              <div>
                제48조 (재위탁 제한)
                <ol>
                  <li>회사는 이용고객으로부터 사전승낙을 얻은 경우를 제외하고 이용고객과의 수탁업무에 관한 본 약관상의 권리와 의무 전부 또는 일부를 제3자에게 양도하거나 재위탁할 수 없습니다.</li>
                  <li>회사가 재위탁받을 수탁회사를 선임한 경우 회사는 재위탁계약서와 함께 그 사실을 즉시 이용고객에게 통보하여야 합니다.</li>
                </ol>
              </div>
              <p>
                부 칙 <br />
                제 1 조 (시행일) 이 약관은 2015년 7월 27일부터 시행 합니다.<br />
              </p>
              <p>
                부 칙 <br />
                제 1 조 (시행일) 이 약관은 2016년 7월 28일부터 시행 합니다.<br />
              </p>
              <p>
                부 칙 <br />
                제 1 조 (시행일) 이 약관은 2017년 6월 28 일부터 시행 합니다.<br />
              </p>
              <p>
                부 칙 <br />
                제 1 조 (시행일) 이 약관은 2017년 10월 25 일부터 시행 합니다.<br />
              </p>
              <p>
                부 칙 <br />
                제 1 조 (시행일) 이 약관은 2017년 11월 23 일부터 시행 합니다.<br />
              </p>
              <p>
                부 칙 <br />
                제 1 조 (시행일) 이 약관은 2018년 5월 10 일부터 시행 합니다.<br />
              </p>
              <p>
                부 칙 <br />
                제 1조 (시행일) 이 약관은 2020년 7월 21일부터 시행 합니다.<br />
              </p>
              <p>
                부 칙 <br />
                제 1조 (시행일) 이 약관은 2022년 10월  1일부터 시행 합니다.<br />
              </p>
              <p>
                부 칙 <br />
                제 1조 (시행일) 이 약관은 2024년 5월  17일부터 시행 합니다.<br />
              </p>

              <div>
                <!-- 이용 요금표 박스 시작. -->
                <br />
                <br />
                <p>[별표 1]</p>
                <p>1. xMS 이용 요금표</p>
                <div class="bbsLstBasic">
                  <table summary="SMS, LMS, MMS 계약 구간(단위) 와 계약(이용) 요금 을 확인 할 수 있습니다.">
                    <caption>
                      계약 단가
                    </caption>
                    <colgroup>
                      <col width="13%" />
                      <col width="13%" />
                      <col width="13%" />
                      <col width="7.5%" />
                      <col width="7.5%" />
                      <col width="7.5%" />
                      <col width="7.5%" />
                      <col width="7.5%" />
                      <col width="7.5%" />
                      <col width="7.5%" />
                      <col width="7.5%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">구분</th>
                        <th colspan="2">계약 구간(단위: 건)</th>
                        <th colspan="8">계약(이용) 요금 (단위: 원, 부가세 포함)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="4">SMS(90Byte)</td>
                        <th>월 이용</th>
                        <th>년 이용</th>
                        <th colspan="4">최고</th>
                        <th colspan="4">최저</th>
                      </tr>
                      <tr>
                        <td>0 ~ 9,999</td>
                        <td>0 ~ 99,999</td>
                        <td colspan="4">27.5</td>
                        <td colspan="4">8.8</td>
                      </tr>
                      <tr>
                        <td>10,000 ~ 99,999</td>
                        <td>100,000 ~ 999,999</td>
                        <td colspan="4">16.5</td>
                        <td colspan="4">8.3</td>
                      </tr>
                      <tr>
                        <td>100,000 ~</td>
                        <td>1,000,000 ~</td>
                        <td colspan="4">13.2</td>
                        <td colspan="4">7.9</td>
                      </tr>
                      <tr>
                        <td rowspan="5">LMS(2000Byte)</td>
                        <th rowspan="2">월 이용</th>
                        <th rowspan="2">년 이용</th>
                        <th colspan="4">최고</th>
                        <th colspan="4">최저</th>
                      </tr>
                      <tr>
                        <th colspan="1">SKT</th>
                        <th colspan="1">KT</th>
                        <th colspan="1">LGU</th>
                        <th colspan="1">통합</th>
                        <th colspan="1">SKT</th>
                        <th colspan="1">KT</th>
                        <th colspan="1">LGU</th>
                        <th colspan="1">통합</th>
                      </tr>
                      <tr>
                        <td>0 ~ 9,999</td>
                        <td>0 ~ 99,999</td>
                        <td>55.0</td>
                        <td>55.0</td>
                        <td>55.0</td>
                        <td>55.0</td>
                        <td>26.4</td>
                        <td>26.4</td>
                        <td>30.8</td>
                        <td>27.3</td>
                      </tr>
                      <tr>
                        <td>10,000 ~ 99,999</td>
                        <td>10,000 ~ 99,999</td>
                        <td>36.3</td>
                        <td>36.3</td>
                        <td>36.3</td>
                        <td>36.3</td>
                        <td>25.9</td>
                        <td>25.9</td>
                        <td>28.0</td>
                        <td>26.4</td>
                      </tr>
                      <tr>
                        <td>100,000 ~</td>
                        <td>1,000,000 ~</td>
                        <td>30.8</td>
                        <td>30.8</td>
                        <td>36.3</td>
                        <td>31.9</td>
                        <td>25.5</td>
                        <td>25.5</td>
                        <td>27.7</td>
                        <td>26.0</td>
                      </tr>
                      <tr>
                        <td rowspan="4">MMS(2000Byte + 1M)</td>
                        <th>월 이용</th>
                        <th>년 이용</th>
                        <th colspan="4">최고</th>
                        <th colspan="4">최저</th>
                      </tr>
                      <tr>
                        <td>0 ~ 9,999</td>
                        <td>0 ~ 99,999</td>
                        <td colspan="4">220</td>
                        <td colspan="4">55.0</td>
                      </tr>
                      <tr>
                        <td>10,000 ~ 24,999</td>
                        <td>100,000 ~ 249,999</td>
                        <td colspan="4">110</td>
                        <td colspan="4">50.6</td>
                      </tr>
                      <tr>
                        <td>25,000 ~</td>
                        <td>250,000 ~</td>
                        <td colspan="4">77</td>
                        <td colspan="4">49.7</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <br />
                <p>2. RCS 이용 요금표</p>
                <div class="bbsLstBasic">
                  <table summary="RCS 계약 구간(단위) 와 계약(이용) 요금 을 확인 할 수 있습니다.">
                    <caption>
                      계약 단가
                    </caption>
                    <colgroup>
                      <col width="13%" />
                      <col width="13%" />
                      <col width="13%" />
                      <col width="30%" />
                      <col width="30%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">구분</th>
                        <th colspan="2">계약 구간(단위: 건)</th>
                        <th colspan="2">계약(이용) 요금 (단위: 원, 부가세 포함)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="4">RCS SMS</td>
                        <th>월 이용</th>
                        <th>년 이용</th>
                        <th>최고</th>
                        <th>최저</th>
                      </tr>
                      <tr>
                        <td>0 ~ 9,999</td>
                        <td>0 ~ 99,999</td>
                        <td>27.5</td>
                        <td>8.8</td>
                      </tr>
                      <tr>
                        <td>10,000 ~ 99,999</td>
                        <td>100,000 ~ 999,999</td>
                        <td>16.5</td>
                        <td>8.3</td>
                      </tr>
                      <tr>
                        <td>100,000 ~</td>
                        <td>1,000,000 ~</td>
                        <td>13.2</td>
                        <td>7.9</td>
                      </tr>
                      <tr>
                        <td rowspan="4">RCS LMS</td>
                        <th>월 이용</th>
                        <th>년 이용</th>
                        <th>최고</th>
                        <th>최저</th>
                      </tr>
                      <tr>
                        <td>0 ~ 9,999</td>
                        <td>0 ~ 99,999</td>
                        <td>55.0</td>
                        <td>26.4</td>
                      </tr>
                      <tr>
                        <td>10,000 ~ 99,999</td>
                        <td>100,000 ~ 999,999</td>
                        <td>36.3</td>
                        <td>25.9</td>
                      </tr>
                      <tr>
                        <td>100,000 ~</td>
                        <td>1,000,000 ~</td>
                        <td>30.8</td>
                        <td>25.5</td>
                      </tr>
                      <tr>
                        <td rowspan="4">RCS MMS</td>
                        <th>월 이용</th>
                        <th>년 이용</th>
                        <th>최고</th>
                        <th>최저</th>
                      </tr>
                      <tr>
                        <td>0 ~ 9,999</td>
                        <td>0 ~ 99,999</td>
                        <td>220</td>
                        <td>55.0</td>
                      </tr>
                      <tr>
                        <td>10,000 ~ 24,999</td>
                        <td>100,000 ~ 249,999</td>
                        <td>110</td>
                        <td>50.6</td>
                      </tr>
                      <tr>
                        <td>25,000 ~</td>
                        <td>250,000 ~</td>
                        <td>77</td>
                        <td>49.7</td>
                      </tr>
                      <tr>
                        <td rowspan="4">RCS 템플릿</td>
                        <th>월 이용</th>
                        <th>년 이용</th>
                        <th>최고</th>
                        <th>최저</th>
                      </tr>
                      <tr>
                        <td>0 ~ 9,999</td>
                        <td>0 ~ 99,999</td>
                        <td>25.3</td>
                        <td>6.6</td>
                      </tr>
                      <tr>
                        <td>10,000 ~ 99,999</td>
                        <td>100,000 ~ 999,999</td>
                        <td>14.3</td>
                        <td>6.1</td>
                      </tr>
                      <tr>
                        <td>100,000 ~</td>
                        <td>1,000,000 ~</td>
                        <td>11.0</td>
                        <td>5.7</td>
                      </tr>
                      <tr>
                        <td rowspan="4">RCS 이미지 템플릿</td>
                        <th>월 이용</th>
                        <th>년 이용</th>
                        <th>최고</th>
                        <th>최저</th>
                      </tr>
                      <tr>
                        <td>0 ~ 9,999</td>
                        <td>0 ~ 99,999</td>
                        <td>160</td>
                        <td>40.1</td>
                      </tr>
                      <tr>
                        <td>10,000 ~ 99,999</td>
                        <td>100,000 ~ 999,999</td>
                        <td>100</td>
                        <td>36.5</td>
                      </tr>
                      <tr>
                        <td>100,000 ~</td>
                        <td>1,000,000 ~</td>
                        <td>60</td>
                        <td>32.9</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <p>※ RCS 이미지 템플릿의 메시지 유형 및 용량은 1MB+500자 범위 내에서 메시지 양식에 따라 다르게 제공됩니다. 단, 금융소비자 보호에 관한 법률 및 금융광고규제 가이드라인과 같이 법규에 의하여 의무적으로 메시지에 포함하여 전송하여야 하는 내용을 위한 용량은 상기 용량(500자)와 별도로 제공될 수 있습니다.</p>

                <br />
                <br />
                <p>3. RCS양방향 이용 요금</p>
                <p class="right" style="margin-bottom: 0px">(부가가치세 포함)</p>
                <div class="bbsLstBasic">
                  <table summary="RCS 계약 구간(단위) 와 계약(이용) 요금 을 확인 할 수 있습니다.">
                    <caption>
                      계약 단가
                    </caption>
                    <colgroup>
                      <col width="20%" />
                      <col width="30%" />
                      <col width="50%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">구분</th>
                        <th>내용</th>
                        <th>비고</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>세션당 이용요금</td>
                        <td>
                          <ul style="margin-bottom: 0px;">
                            <li>5.7원/건,</li>
                            <li>최대 57원/세션</li>
                          </ul>
                        </td>
                        <td>‘최대 57원/세션’의 의미: “이용자”가 전송한 “RCS양방향” 메시지가 한 세션 내에서 10건 이상일 경우, 57원을 과금함</td>
                      </tr>
                      <tr>
                        <td>세션기준</td>
                        <td>“고객”의 이동통신 회선번호에서 전송한 기업의 전화번호당 24시간 단위(24시간의 기준시: 04시)</td>
                        <td class="left">
                          <ul>
                            <li>(예시)</li>
                            <li>“고객”의 이동통신 회선번호 010-1111-2222에서 기업의 전화번호 1544-0001로 (N)일 04:10분에 문의를 전송한 후 (N+1)일 03:50분까지 12건 응답메시지를 수신하고, 04:00 ~ 05:10분까지 6건 응답메시지를 수신했을 경우 요금:</li>
                            <li>-	(N)일의 세션 요금: 5.7원 x 12건 = 68.4원 </li>
                            <li>→ 세션 최대요금 57원 적용</li>
                            <li>-	(N+1)일의 세션 요금: 5.7 x 6건 = 34.2원 </li>
                            <li>-	요금 합계: 57원+34.2원 = 91.2원 부과</li>
                            <li>※ 고객의 이동통신 회선번호는 동일하고 전송한 기업의 전화번호가 다른 경우 별도의 세션으로 과금함</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>사용조건</td>
                        <td>기업이 “고객”의 문의/요청을 받아 이에 대한 응답 메시지를 전송하는 경우</td>
                        <td class="left">
                          <ul>
                            <li>(예시)</li>
                            <li>”고객”이 기업의 전화번호로 문자를 전송하여 이에 응답한 경우</li>
                            <li>“고객”이 문자함의 대화방 메뉴를 선택 시 응답한 경우</li>
                            <li>“고객”이 기업의 대화방을 검색하여 최초 진입 시 응답한 경우</li>
                            <li>○ 대화방 : "고객"이 단말기 문자함에서 메시지를 수신했을 때 기업의 발신자 번호 기준으로 묶이는 단위</li>
                            <li>○ 대화방 메뉴 : 기업이 “고객”에게 알리고자 하는 내용을 항목화하여 대화방에 고정적으로 표시하는 메뉴</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- 이용 요금표 박스 끝. -->

              <div>
                [“RCS 양방향” 메시지 유형 및 용량]
                <ul>
                  <li>※ “RCS 양방향” 메시지 유형 및 용량은 RBC(RCS Biz Center)를 통해서 공지하며, 사전 공지를 통해 "회사" 정책에 따라 변경될 수 있음</li>
                  <li>※ RCS양방향 메시지는 고객의 실시간 문의/요청(예: 고객이 대화방에서 문의메시지를 전송하거나, 대화방 메뉴를 선택 등)에 대해서 응답하는 모든 메시지(챗봇응답 및 대화방메뉴 자동응답 메시지 등)에 대해 과금한다.</li>
                </ul>
              </div>
              <div>
                (이용조건)
                <ul>
                  <li>1) LGU+ 기업메시징서비스는 기업대상 서비스로 법인 또는 개인사업자 대상 서비스임. 단, 중앙선거관리위원회 관리를 받는 개인은 선불이용이 가능함</li>
                  <li>2) 기업의 특성(연 단위 예산확보 및 집행)을 고려하여 월/년 단위 발송기준으로 계약을 체결하며, 건당 발송 성공건수와 계약 단가를 곱하여 요금을 청구함</li>
                  <li>3) 구간 내 슬라이딩 단가를 기준으로 계약을 체결할 수 있으며, 이용건수에 따라 요금을 청구함</li>
                  <li>4) 이용고객에게 설치료 및 부대 서버관리비용을 면제해주는 대신, 월 최저이용료를 부과할 수 있음</li>
                  <li>5) LGU+는 추가할인정책과 프로모션을 시행할 수 있으며, 이용고객은 당사 서비스 홈페이지에 게시되는 할인 정책과 프로모션을 참조하여 이용할 수 있음</li>
                </ul>
              </div>
            </div>
            <!-- 이용약관 동의 박스 끝. -->
          </div>
        </div>
      </div>
    </div>
    <!--	e: 	contents	-->
  </section>
</template>
<script>
import MobileGnb from "@/components/MobileGnb.vue";

export default {
  name: "useTerms",
  components: { MobileGnb },
  data() {
    return {
      example: "",
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeUnmount() {},
  unmounted() {},
  methods: {},
};
</script>
